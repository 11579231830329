<template>
  <b-card>
    <div class="d-flex justify-content-between align-items-center w-100 mb-1">
      <h3>حجوزات الإجراءات</h3>
      <div v-can="{ slug: $route.meta.slug, action: 'C' }">
        <b-button variant="primary" @click="onClickedAdd()">
          <feather-icon icon="userIcon" /> إضافة
        </b-button>
      </div>
    </div>
    <div class="border pt-1 px-1 rounded-lg shadow-sm">
      <b-row>
        <b-col cols="3">
          <form-select
            label=""
            v-model="procedure_id"
            placeholder="إختر نوع الإجراء ..."
            class="w-50"
            :reduce="(x) => x.id"
            :getOptionLabel="(x) => x.title"
            id="opportunity_type_id"
            ep="select/procedures"
            rules="required"
          />
        </b-col>
        <b-col cols="3">
          <form-input
            label=""
            v-model="filter"
            placeholder="  ابحث هنا عن اسم المستخدم..."
            class="w-50"
          />
        </b-col>
        <b-col cols="6">
          <div class="d-flex justify-content-end">
            <b-button
              v-for="(item, i) in filterDate"
              :key="i"
              size="md"
              variant="outline-success"
              pill
              class="mx-1"
              @click="
                utils.status = item.status;
                $refs.estatesTable.refreshTable();
              "
              :class="utils.status == item.status ? 'bg-light-success' : ''"
              >{{ item.label }}</b-button
            >
          </div>
        </b-col>
      </b-row>
    </div>
    <data-table
      ep="procedure-appointments"
      ref="estatesTable"
      :fields="fields"
      :filter="filter"
      :utils.sync="utils"
    >
      <template #cell(actions)="row">
        <div class="d-flex">
          <b-button
            v-if="utils.status == 'N'"
            size="sm"
            variant="flat-danger"
            @click="
              confirm({ status: 'R', id: row.item.id }).then(() =>
                $refs.estatesTable.refreshTable()
              )
            "
            v-can="{ slug: $route.meta.slug, action: 'O' }"
          >
            <feather-icon icon="XCircleIcon" />
            <span class="align-middle">رفض</span>
          </b-button>
          <b-button
            v-can="{ slug: $route.meta.slug, action: 'O' }"
            v-if="utils.status == 'N'"
            size="sm"
            variant="flat-success"
            @click="
              confirm({ status: 'A', id: row.item.id }).then(() =>
                $refs.estatesTable.refreshTable()
              )
            "
          >
            <feather-icon icon="CheckCircleIcon" />
            <span class="align-middle">قبول</span>
          </b-button>
          <b-dropdown
            v-if="utils.status == 'N'"
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item @click="onUpdateClicked(row.item)" v-can="{ slug: $route.meta.slug, action: 'U' }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">تعديل</span>
            </b-dropdown-item>

            <!-- <b-dropdown-item @click="onDelete(row.item)">
            <feather-icon icon="Trash2Icon" />
            <span class="align-middle ml-50">حذف</span>
          </b-dropdown-item> -->
          </b-dropdown>
        </div>
      </template>
    </data-table>

    <form-modal
      ref="estateModalAdd"
      :formSchema="estatesFormSchema"
      title="إضافة "
      v-model="activModel"
      @confirm="onModalConfirmed"
      :loading="loading"
    >
    </form-modal>
  </b-card>
</template>

<script>
import {
  BCard,
  BListGroup,
  BListGroupItem,
  BRow,
  BCol,
  BButton,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import DataList from "@/components/data-list/index.vue";
import { mapActions, mapGetters } from "vuex";
import FormModal from "@/components/FormModal.vue";
import FormSelect from "@/components/form-select/index.vue";
import FormInput from "@/components/form-input/index.vue";
import { debounce } from "lodash";
import DataTable from "@/components/data-table/index.vue";
export default {
  components: {
    BCard,
    BListGroup,
    BListGroupItem,
    DataList,
    BRow,
    BCol,
    BButton,
    FormModal,
    FormSelect,
    FormInput,
    BDropdown,
    BDropdownItem,
    DataTable,
  },
  data() {
    return {
      fields: [
        { key: "procedure.title", label: "اسم الإجراء" },
        { key: "appointment_date", label: "تاريخ" },
        { key: "full_name", label: "اسم المستخدم" },
        { key: "phone_number", label: "رقم التواصل" },
        { key: "actions", label: "" },
      ],
      activModel: false,
      filter: "",
      filterDate: [
        { id: 1, label: "الطلبات الجديدة", status: "N" },
        { id: 1, label: "الطلبات المقبولة", status: "A" },
        { id: 1, label: "الطلبات المرفوضة", status: "R" },
      ],
      utils: {
        status: "N",
      },
      procedure_id: "",
      estatesFormSchema: {
        full_name: {
          component: "FormInput",
          key: "full_name",
          attrs: {
            label: "اسم المستخدم",
            rules: "required",
          },
        },
        phone_number: {
          component: "FormMask",
          key: "phone_number",
          attrs: {
            options: "phone",
            rules: "required|min:10",
            label: "رقم التواصل",
            dir: "ltr",
          },
        },
        appointment_date: {
          component: "FormDatepicker",
          key: "appointment_date",
          attrs: {
            id: "appointment_date",
            label: "تاريخ ",
            rules: "required",
            placeholder: "YY-MM-DD",
          },
        },
        procedure_id: {
          component: "FormSelect",
          key: "procedure_id",
          attrs: {
            reduce: (x) => x.id,
            getOptionLabel: (x) => x.title,
            id: "procedure_id",
            label: "نوع الإجراء",
            ep: "select/procedures",
            rules: "required",
          },
        },
      },
    };
  },
  methods: {
    ...mapActions("manageCenter/bookings/procedure", [
      "confirm",
      "updateBookingProcedures",
      "createBookingProcedures",
      "deleteBookingProcedures",
    ]),
    onModalConfirmed(form) {
      if (form.id) {
        this.updateBookingProcedures({ id: form.id, data: form }).then(() => {
          this.activModel = false;
          this.$refs.estatesTable.refreshTable();
        });
      } else {
        this.createBookingProcedures({ data: form }).then(() => {
          this.activModel = false;
          this.$refs.estatesTable.refreshTable();
        });
      }
    },
    onClickedAdd() {
      this.$refs.estateModalAdd.init({});
      this.activModel = true;
    },
    onUpdateClicked(form) {
      var toUpdate = {
        id: form.id,
        procedure_id: form.procedure.id,
        full_name: form.full_name,
        appointment_date: form.appointment_date,
        phone_number: form.phone_number,
      };
      this.$refs.estateModalAdd.init(toUpdate);
      this.activModel = true;
    },

    onDelete(item) {
      // console.log(id)
      this.$bvModal
        .msgBoxConfirm("هل أنت متأكد من حذف الحجز", {
          title: "تأكيد حذف الحجز",
          size: "sm",
          okVariant: "danger",
          okTitle: "تأكيد",
          cancelTitle: "إلغاء",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value)
            this.deleteBookingProcedures({ id: item.id }).then(() => {
              this.$refs.estatesTable.refreshTable();
            });
        });
    },
  },
  watch: {
    procedure_id: debounce(function (value) {
      this.$refs.refprocedure.refreshOptions({
        procedure_id: this.procedure_id,
      });
    }, 700),
  },
  computed: {
    ...mapGetters("manageCenter/bookings/procedure", ["loading"]),
  },
};
</script>
